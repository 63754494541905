<template>
  <basic-page-layout :padding="true">
    <div class="ion-text-center">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 8L12 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 16.01L12.01 15.9989" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 3H4V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 11V13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 11V13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 3H20V6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 21H4V18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 21H20V18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <h4>
        {{ $t('misc.404') }}
      </h4>
    </div>
  </basic-page-layout>
</template>

<script lang="ts">
import BasicPageLayout from '@/views/basic-page-layout.vue'

export default {
  name: 'ImprintView',
  components: {
    BasicPageLayout
  }
}
</script>

<style scoped lang="scss">
svg {
  color: #9f0000;
  max-height: 50vh;
  max-width: 80vw;
}
h4 {
  white-space: pre-line;
}
</style>

